import { gql } from '@apollo/client'

export const CORE_USER_FIELDS = gql`
    fragment CoreUserFields on User {
        id,
        first_name,
        ref_link,
        last_name,
        full_name,
        title,
        email,
        role,
        intercom_hash,
        shippingAddress {
            street,
            city,
            email,
            zip,
            country_id
            country {
                id,
                code
                alpha3_code
            }
        },
        billingAddress {
            street,
            city,
            email,
            zip,
            country_id
            country {
                id,
                code
                alpha3_code
            }
        },
    }
`

export const CURRENT_USER = gql`
    ${CORE_USER_FIELDS}
    query CurrentUser {
        currentUser {
            ...CoreUserFields
        }
    }
`

export const CURRENT_USER_WITH_ADDRESSES = gql`
    query CurrentUser {
        currentUser {
            id,
            first_name,
            ref_link,
            last_name,
            full_name,
            title,
            email,
            role,
            shippingAddress {
                street,
                city,
                email,
                zip,
                country_id
                country {
                    id,
                    code
                    alpha3_code
                }
            },
            billingAddress {
                street,
                city,
                email,
                zip,
                country_id
                country {
                    id,
                    code
                    alpha3_code
                }
            }
        }
    }
`

export const CURRENT_USER_WITH_COMPANY = gql`
    ${CORE_USER_FIELDS}
    query CurrentUser {
        currentUser {
            ...CoreUserFields
            company {
                id,
                name,
                ref_id,
                country_id,
                country {
                    id,
                    code
                    alpha3_code
                }
                street,
                zip,
                email,
                website,
                descriptions {
                    description
                    language,
                    id
                },
                vat_number,
                trade_register_number,
                city,
                subscription_type,
                company_constraints {
                    product_count
                    commission
                    has_cloud
                    has_lead
                    has_download
                    has_services
                },
                activeProductCount,
                paymentMethods {
                    __typename,
                    id,
                    config {
                        ...on StripeConfig {
                            api_key
                            webhook_secret
                            publishable_key
                        },
                        ...on PayPalConfig {
                            client_id
                            client_secret
                            webhook_id
                        }
                    },
                    is_active,
                    payment_method
                }
                logo {
                    url,
                    width,
                    height
                },
                terms_of_service,
                privacy_declaration
            }
        }
    }
`

export const CURRENT_USER_WITH_ORDERS = gql`
    ${CORE_USER_FIELDS}
    query CurrentUser {
        currentUser {
            ...CoreUserFields
        }
    }
`

export const CURRENT_USER_WITH_ALL = gql`
    ${CORE_USER_FIELDS}
    query CurrentUser {
        currentUser {
            ...CoreUserFields
            company {
                id,
                name,
                country_id,
                ref_id,
                country {
                    id,
                    code
                    alpha3_code
                }
                street,
                zip,
                email,
                website,
                phone_number,
                social_media {
                    facebook,
                    twitter,
                    linkedin,
                    instagram,
                    youtube,
                },
                descriptions {
                    description
                    language,
                    id
                },
                services {
                    id
                    service
                },
                vat_number,
                trade_register_number,
                city,
                slug,
                logo {
                    url,
                    width,
                    height
                },
                subscription_type,
                company_constraints {
                    product_count
                    commission
                    has_lead
                    has_cloud
                    has_download
                    has_services
                },
                activeProductCount,
                paymentMethods {
                    __typename,
                    id,
                    config {
                        ...on StripeConfig {
                            api_key
                            webhook_secret
                            publishable_key
                        },
                        ...on PayPalConfig {
                            client_id
                            client_secret
                            webhook_id
                        }
                    },
                    is_active,
                    payment_method
                },
                terms_of_service,
                privacy_declaration
            }
        }
    }
`
