import Script from 'next/script'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectMe } from '../features/auth/authReducer'

export default function IntercomChat () {
  const router = useRouter()
  const me = useSelector(state => selectMe(state.auth))

  useEffect(() => {
    const handleRouteChange = (url) => {
      window.Intercom('update', { last_request_at: parseInt((new Date()).getTime() / 1000) })
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [])

  useEffect(() => {
    if (!me) {
      return
    }

    window.Intercom('update', {
      email: me.email,
      user_id: me.id,
      user_hash: me.intercom_hash,
      name: me.full_name
    })
  }, [me])

  return (
    <>
      <Script
        id="intercom-chat"
        strategy="afterInteractive"
      >
        {`
        window.intercomSettings = {
          api_base: "https://api-iam.intercom.io",
          app_id: "phxrzani"
        };

        (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/phxrzani';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
        `}
      </Script>
    </>
  )
}
